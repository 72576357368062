<template>
  <div class="main-content mt-4">
    <div class="layout-px-spacing">
      <div class="row layout-spacing layout-top-spacing" id="cancel-row">
        <div class="col-lg-12">
          <div class="widget-content searchable-container grid">
            <div class="row">
              <div
                class="col-xl-4 col-lg-5 col-md-5 col-sm-7 filtered-list-search layout-spacing align-self-center"
              >
                <form class="form-inline my-2 my-lg-0">
                  <div class="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-search"
                    >
                      <circle cx="11" cy="11" r="8"></circle>
                      <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                    </svg>
                    <input
                      type="text"
                      class="form-control product-search"
                      id="input-search"
                      placeholder="Search Users..."
                    />
                  </div>
                </form>
              </div>

              <div
                class="col-xl-8 col-lg-7 col-md-7 col-sm-5 text-sm-right text-center layout-spacing align-self-center"
              >
                <div
                  class="d-flex justify-content-sm-end justify-content-center"
                >
                  <svg
                    id="btn-add-contact"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-user-plus"
                  >
                    <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                    <circle cx="8.5" cy="7" r="4"></circle>
                    <line x1="20" y1="8" x2="20" y2="14"></line>
                    <line x1="23" y1="11" x2="17" y2="11"></line>
                  </svg>
                </div>

                <!-- Modal -->
                <div
                  class="modal fade"
                  id="addContactModal"
                  role="dialog"
                  aria-labelledby="addContactModalTitle"
                  aria-hidden="true"
                >
                  <div
                    class="modal-dialog modal-dialog-centered"
                    role="document"
                  >
                    <div class="modal-content">
                      <div class="modal-body">
                        <i class="fa fa-close" data-dismiss="modal"></i>
                        <div class="add-contact-box">
                          <div class="add-contact-content">
                            <div class="text-center">
                              <p>Add New User</p>
                            </div>

                            <form>
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="contact-name">
                                    <input
                                      type="text"
                                      id="c-name"
                                      v-model="newUser.firstname"
                                      class="form-control"
                                      placeholder="First Name"
                                    />
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="contact-name">
                                    <input
                                      type="text"
                                      id="c-email"
                                      v-model="newUser.lastname"
                                      class="form-control"
                                      placeholder="Last Name"
                                    />
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-md-12">
                                  <div class="contact-occupation">
                                    <input
                                      type="email"
                                      id="c-occupation"
                                      v-model="newUser.email"
                                      class="form-control"
                                      placeholder="Email Address"
                                    />
                                  </div>
                                </div>

                                <div class="col-md-12">
                                  <div class="contact-phone">
                                    <select
                                      class="form-control"
                                      v-model="newUser.role"
                                      name="role"
                                      id=""
                                    >
                                      <option selected disabled>
                                        --select role--
                                      </option>
                                      <option
                                        v-for="(role, index) in $root.userRoles"
                                        :key="index"
                                        :value="role"
                                      >
                                        {{ role.toUpperCase() }}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button
                          class="btn"
                          id="btn_hide_add_new_user_modal"
                          data-dismiss="modal"
                        >
                          <i class="flaticon-delete-1"></i> Dismiss
                        </button>

                        <button
                          id="btn-add"
                          @click.prevent="addNewUser()"
                          class="btn"
                          :disabled="loadingNewUser"
                        >
                          <b-spinner small v-if="loadingNewUser"></b-spinner>
                          <span v-else
                            >Add <i class="fa fa-paper-plane"></i
                          ></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Edit Modal -->
                <div
                  class="modal fade"
                  id="editUserModal"
                  role="dialog"
                  aria-labelledby="editContactModalTitle"
                  aria-hidden="true"
                >
                  <div
                    class="modal-dialog modal-dialog-centered"
                    role="document"
                  >
                    <div class="modal-content">
                      <div class="modal-body">
                        <i class="fa fa-close" data-dismiss="modal"></i>
                        <div class="add-contact-box">
                          <div class="add-contact-content">
                            <div class="text-center">
                              <p>Edit User</p>
                            </div>

                            <form>
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="contact-name">
                                    <input
                                      type="text"
                                      id="c-name"
                                      v-model="editUser.firstname"
                                      class="form-control"
                                      placeholder="First Name"
                                    />
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="contact-name">
                                    <input
                                      type="text"
                                      id="c-email"
                                      v-model="editUser.lastname"
                                      class="form-control"
                                      placeholder="Last Name"
                                    />
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-md-12">
                                  <div class="contact-occupation">
                                    <input
                                      type="email"
                                      id="c-occupation"
                                      v-model="editUser.email"
                                      class="form-control"
                                      readonly
                                      placeholder="Email Address"
                                    />
                                  </div>
                                </div>

                                <div class="col-md-12">
                                  <div class="contact-phone">
                                    <select
                                      class="form-control"
                                      v-model="editUser.role"
                                      name="role"
                                      id=""
                                    >
                                      <option selected disabled>
                                        --select role--
                                      </option>
                                      <option value="tech">Tech</option>
                                      <option value="admin">Admin</option>
                                      <option value="accounts">Accounts</option>
                                      <option value="management">
                                        Management
                                      </option>
                                      <option value="client services">
                                        Client Services
                                      </option>
                                      <option value="elderly services">
                                        Elderly Services
                                      </option>
                                      <option value="medical services">
                                        Medical Services
                                      </option>
                                      <option value="ganar">Ganar</option>
                                      <option value="caregiver services">
                                        Caregiver Services
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button class="btn" data-dismiss="modal">
                          <i class="flaticon-delete-1"></i> Dismiss
                        </button>

                        <button
                          id="btn-edit"
                          @click.prevent="completeEditUser()"
                          class="btn"
                          :disabled="loadingEditUser"
                        >
                          <b-spinner small v-if="loadingEditUser"></b-spinner>
                          <span v-else
                            >Save Changes <i class="fa fa-paper-plane"></i
                          ></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Delete Modal -->
                <div
                  class="modal fade"
                  id="deleteUserModal"
                  role="dialog"
                  aria-labelledby="deleteContactModalTitle"
                  aria-hidden="true"
                >
                  <div
                    class="modal-dialog modal-dialog-centered"
                    role="document"
                  >
                    <div class="modal-content">
                      <div class="modal-body">
                        <i class="fa fa-close" data-dismiss="modal"></i>
                        <div class="add-contact-box">
                          <div class="add-contact-content">
                            <div class="text-center">
                              <p class="text-danger">
                                Are you sure you want to delete this User?
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button class="btn" data-dismiss="modal">Cancel</button>

                        <button
                          @click.prevent="completeDeleteUser()"
                          class="btn btn-danger text-white"
                          :disabled="loadingDeleteUser"
                        >
                          <b-spinner small v-if="loadingDeleteUser"></b-spinner>
                          <span v-else
                            >Confirm Delete <i class="fa fa-trash"></i
                          ></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="p-2 text-center" v-if="loading">
              <b-card class="">
                <b-skeleton animation="wave" width="85%"></b-skeleton>
                <b-skeleton animation="wave" width="67%"></b-skeleton>
                <b-skeleton animation="wave" width="70%"></b-skeleton>
                <b-skeleton animation="wave" width="82%"></b-skeleton>
                <b-skeleton animation="wave" width="65%"></b-skeleton>
                <b-skeleton animation="wave" width="90%"></b-skeleton>
                <b-skeleton animation="wave" width="95%"></b-skeleton>
                <b-skeleton animation="wave" width="70%"></b-skeleton>
                <b-skeleton animation="wave" width="98%"></b-skeleton>
                <b-skeleton animation="wave" width="75%"></b-skeleton>
                <b-skeleton animation="wave" width="92%"></b-skeleton>
                <b-skeleton animation="wave" width="85%"></b-skeleton>
                <b-skeleton animation="wave" width="74%"></b-skeleton>
                <b-skeleton animation="wave" width="89%"></b-skeleton>
                <b-skeleton type="button"></b-skeleton>
              </b-card>
            </div>

            <div class="searchable-items grid" v-else>
              <div class="items items-header-section">
                <div class="item-content">
                  <div class="">
                    <h4>Name</h4>
                  </div>
                  <div class="user-email">
                    <h4>Email</h4>
                  </div>
                  <div class="action-btn">Action</div>
                </div>
              </div>

              <div class="items shadow" v-for="user in users" :key="user.id">
                <div class="item-content">
                  <div class="user-profile">
                    <img
                      :src="user.image"
                      class="img-fluid w-50"
                      alt="avatar"
                    />
                    <div class="user-meta-info">
                      <p
                        class="user-name"
                        :data-name="`${user.firstname} ${user.lastname}`"
                      >
                        {{ user.firstname }} {{ user.lastname }}
                      </p>
                      <p class="user-work" :data-occupation="`${user.role}`">
                        {{ user.role }}
                      </p>
                    </div>
                  </div>
                  <div
                    class="user-email text-center"
                    style="justify-content: center"
                  >
                    <p class="usr-email-addr" :data-email="`${user.role}`">
                      {{ user.email }}
                    </p>
                  </div>
                  <div class="action-btn">
                    <svg
                      @click.prevent="startEditUserModal(user)"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-edit-2 edit"
                    >
                      <path
                        d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"
                      ></path>
                    </svg>

                    <svg
                      @click.prevent="startDeleteUserModal(user)"
                      v-if="user.id != currentUser.id"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-user-minus delete"
                    >
                      <path
                        d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"
                      ></path>
                      <circle cx="8.5" cy="7" r="4"></circle>
                      <line x1="23" y1="11" x2="17" y2="11"></line>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { http } from "@/utils/http";
import { endpoints } from "@/utils/endpoints";
import { mapGetters } from "vuex";
import { newUser, editUser } from "@/models/user";
import $ from "jquery";
import _ from "lodash";

export default {
  name: "UserAccounts",
  components: {},

  data() {
    return {
      currentUser: {},
      users: {},
      loading: true,
      loadingNewUser: false,
      loadingEditUser: false,
      loadingDeleteUser: false,
      newUser: new newUser(),
      editUser: new editUser(),
      deleteUser: {
        id: "",
      },
    };
  },

  computed: {
    ...mapGetters(["url"]),
  },

  mounted() {
    this.initJqueryUI();
    this.fetchUsers();
    this.currentUser = this.$store.state.user;
  },

  methods: {
    initJqueryUI() {
      $(document).ready(function () {
        checkall("contact-check-all", "contact-chkbox"); // eslint-disable-line

        $("#input-search").on("keyup", function () {
          var rex = new RegExp($(this).val(), "i");
          $(".searchable-items .items:not(.items-header-section)").hide();
          $(".searchable-items .items:not(.items-header-section)")
            .filter(function () {
              return rex.test($(this).text());
            })
            .show();
        });

        $(".view-grid").on("click", function (event) {
          event.preventDefault();
          $(this)
            .parents(".switch")
            .find(".view-list")
            .removeClass("active-view");
          $(this).addClass("active-view");
          $(this).parents(".searchable-container").removeClass("list");
          $(this).parents(".searchable-container").addClass("grid");
          $(this)
            .parents(".searchable-container")
            .find(".searchable-items")
            .removeClass("list");
          $(this)
            .parents(".searchable-container")
            .find(".searchable-items")
            .addClass("grid");
        });

        $(".view-list").on("click", function (event) {
          event.preventDefault();
          // Act on the event
          $(this)
            .parents(".switch")
            .find(".view-grid")
            .removeClass("active-view");
          $(this).addClass("active-view");
          $(this).parents(".searchable-container").removeClass("grid");
          $(this).parents(".searchable-container").addClass("list");
          $(this)
            .parents(".searchable-container")
            .find(".searchable-items")
            .removeClass("grid");
          $(this)
            .parents(".searchable-container")
            .find(".searchable-items")
            .addClass("list");
        });

        $("#btn-add-contact").on("click", function () {
          $("#addContactModal #btn-add").show();
          $("#addContactModal #btn-edit").hide();
          $("#addContactModal").modal("show");
        });
      });
    },

    fetchUsers() {
      this.loading = true;
      http
        .get(endpoints.GET_ALL_USERS)
        .then((response) => {
          this.users = response;
          this.loading = false;
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },

    addNewUser() {
      if (Object.values(this.newUser).some((x) => x == "")) {
        this.$toast.error("Missing input fields. Please try again!");
      } else {
        this.loadingNewUser = true;
        http
          .post(endpoints.CREATE_USER, {
            ...this.newUser,
          })
          .then((response) => {
            this.$toast.success(response);
            this.newUser = _.mapValues(this.newUser, () => "");
            this.loadingNewUser = false;
            $("#addContactModal").modal("hide");
            this.fetchUsers();
          })
          .catch((error) => {
            this.loadingNewUser = false;
            this.$toast.error(
              error.response?.data?.message || error.response?.message
            );
          });
      }
    },

    startEditUserModal(user) {
      this.editUser = user;
      $("#editUserModal").modal("show");
    },

    completeEditUser() {
      if (Object.values(this.editUser).some((x) => x == "")) {
        this.$toast.error("Missing input fields. Please try again!");
      } else {
        this.loadingEditUser = true;
        http
          .put(endpoints.UPDATE_USER_PROFILE, {
            ...this.editUser,
          })
          .then((response) => {
            this.loadingEditUser = false;
            $("#editUserModal").modal("hide");
            this.$toast.success(response);
          })
          .catch((error) => {
            this.loadingEditUser = false;
            this.$toast.error(
              error.response?.data?.message || error.response?.message
            );
          });
      }
    },

    startDeleteUserModal(user) {
      this.deleteUser.id = user.id;
      $("#deleteUserModal").modal("show");
    },

    completeDeleteUser() {
      this.loadingDeleteUser = true;
      http
        .delete(endpoints.DELETE_USER.replace(":id", this.deleteUser.id))
        .then((response) => {
          this.loadingDeleteUser = false;
          $("#deleteUserModal").modal("hide");
          this.$toast.success(response);
          this.users = this.users.filter((x) => x.id != this.deleteUser.id);
        })
        .catch((error) => {
          this.loadingDeleteUser = false;
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },
  },
};
</script>

<style scoped src="@/assets/css/forms/theme-checkbox-radio.css"></style>
<style scoped src="@/assets/css/apps/contacts.css"></style>
